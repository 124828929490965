import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Column = _resolveComponent("Column")!
  const _component_DataTable = _resolveComponent("DataTable")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_DataTable, {
      value: _ctx.logSchedule,
      sortMode: "multiple",
      class: "p-datatable-sm"
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.detailHeader, (y) => {
          return (_openBlock(), _createBlock(_component_Column, {
            key: y.field,
            field: y.field,
            header: y.headerName,
            sortable: ""
          }, null, 8, ["field", "header"]))
        }), 128))
      ]),
      _: 1
    }, 8, ["value"])
  ]))
}