
import { defineComponent, getCurrentInstance, onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { Form, Field, FieldArray, ErrorMessage } from 'vee-validate'
import apiUseCase from '@/usecase/apiUseCase'
import moment from 'moment'
import Card from 'primevue/card'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import Checkbox from 'primevue/checkbox'
import InputText from 'primevue/inputtext'
import Dropdown from 'primevue/dropdown'
import Button from 'primevue/button'

export default defineComponent({
  name: 'VerbalReport',
  components: {
    DataTable,
    Column
  },
  setup() {
    const endpointPlotting = '/roster/v1/Plotting'
    const store = useStore()
    const logSchedule = ref([])
    const detailHeader = ref([
      {
        field: 'nopol',
        headerName: 'Nopol',
        searchable: true,
        fieldSearch: 'nopol'
      },
      {
        field: 'date',
        headerName: 'Tanggal',
        searchable: true,
        fieldSearch: 'date'
      },
      {
        field: 'time',
        headerName: 'Jam',
        searchable: true,
        fieldSearch: 'time'
      }
    ])
    const datatableSource = ref([
      { nopol: 'AG 1111 AA', date: '1 Okt 2023', time: '00:01' },
      { nopol: 'AG 2222 BB', date: '2 Okt 2023', time: '00:02' },
      { nopol: 'AG 3333 CC', date: '3 Okt 2023', time: '00:03' }
    ])

    const getAllData = () => {
      apiUseCase.get(`${endpointPlotting}?condecturUsername=${store.state.plotting.selectedConductor.Username}`).then((response) => {
        console.log(response)
        logSchedule.value = response.result.map((data: any) => ({
          nopol: data.RegNumber,
          date: moment(data.plottingDate).format('DD-MM-YYYY'),
          time: data.DepartureTime.slice(0, 5)
        }))
        console.log('logSchedule', logSchedule.value)
      })
    }

    onMounted(() => {
      getAllData()
    })

    return {
      detailHeader,
      logSchedule,
      datatableSource
    }
  }
})
