
import { computed, defineComponent, getCurrentInstance, onBeforeUnmount, onMounted, reactive, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { Form, Field, FieldArray, ErrorMessage } from 'vee-validate'
import apiUseCase from '@/usecase/apiUseCase'
import moment from 'moment'
import * as Yup from 'yup'
import Card from 'primevue/card'
import InputText from 'primevue/inputtext'
import Button from 'primevue/button'
import { DatePicker } from 'v-calendar'
import vSelect from 'vue-select'
import SelectFetch from '@/views/components/form/SelectAsync.vue'
import HjpTabView from '@/views/components/tab/HjpTabView.vue'
import KondekturActiveScheduleList from '@/views/pages/plotting-conductor/components/KondekturActiveScheduleList.vue'
import KondekturLog from '@/views/pages/plotting-conductor/components/KondekturLog.vue'
import SelectConductorForm from '@/views/pages/plotting-conductor/form/SelectKondektur.vue'
import HjpAlertModal from '@/views/components/modal/HjpAlertModal.vue'
import { ITrayek } from '@/utils/interfaces/iTrayek'
import ConfirmModal from '@/views/components/modal/ConfirmModal2.vue'
import returnUrlPrams from '@/utils/helpers/returnUrlPrams'

type TFieldValue = {
  (field: string, value: any): void
}

export default defineComponent({
  name: 'VerbalReport',
  components: {
    Card,
    Form,
    Field,
    FieldArray,
    ErrorMessage,
    SelectFetch,
    InputText,
    Button,
    HjpTabView,
    KondekturActiveScheduleList,
    KondekturLog,
    DatePicker,
    vSelect,
    ConfirmModal,
    SelectConductorForm,
    HjpAlertModal
  },
  setup() {
    const endpointTrayek = '/management/v1/Trayek'
    const endpointPlotting = '/roster/v1/Plotting'

    const app = getCurrentInstance()
    const {
      $icon,
      $toast
    } = app!.appContext.config.globalProperties
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const icon = $icon
    const currentSchedules = ref([]) as any
    const refConfirmModal = ref<InstanceType<typeof ConfirmModal>>()
    const defaultValue = ref(
      {
        vehicle: null,
        date: null,
        time: null,
        driver: null,
        helper: null,
        optionTime: []
      }
    )
    const initialValue = ref({
      data: [
        {
          vehicle: null,
          date: null,
          time: null,
          driver: null,
          helper: null,
          optionTime: []
        }
      ]
    })
    const schema = Yup.object().shape({
      data: Yup.array().of(
        Yup.object().shape({
          vehicle: Yup.object().required('Bus tidak boleh kosong'),
          driver: Yup.object().required('Sopir tidak boleh kosong'),
          date: Yup.string().required('Tanggal tidak boleh kosong'),
          time: Yup.object().required('Jam (PP) tidak boleh kosong')
        })
      ).test('is-unique', 'Plot Kondektur dan Sopir tidak boleh ada yang sama', (val) => {
        // console.log('val', val)
        if (!Array.isArray(val)) {
          return true
        }
        const set = new Set()
        // console.log('set', set)
        for (let i = 0; i < val.length; i++) {
          if (val[i]) {
            const temp: any = val[i]
            if (!temp.time && !temp.date) {
              // console.log('skip')
              return true
            }
            const data = `${temp.vehicle?.Id} - ${temp.date} - ${temp.time?.ppOrder} - ${temp.driver?.Id}`
            if (set.has(data) && data) {
              // console.log('exist')
              return false
            }
            set.add(data)
          }
        }
        return true
      })
    })
    const tabHeader = ref([
      { title: 'Jadwal Aktif', value: 0 },
      { title: 'Log Kondektur', value: 1 }
    ])
    const activeTab = ref(0)
    const isLoading = ref(true)
    const dataForm = ref([]) as any
    const showConfirmDialog = ref(false)

    const modalConfirmation = reactive({
      show: false,
      imgContent: require('@/assets/img/modal-confirmation/roster-confirmation.svg'),
      title: '',
      subTitle: '',
      btnSubmitTitle: 'OK',
      subTitleHtml: false,
      isDelete: false
    })

    const backToListPage = () => {
      router.push({
        name: 'plotting-conductor'
      })
    }

    const tabsChange = (val: any) => {
      activeTab.value = val
    }

    const getVehicleTrayek = async (val: any, index: number, setFieldValue: TFieldValue) => {
      const {
        result,
        error
      } = await apiUseCase.get(`${endpointTrayek}/${val.Trayek.Id}`)
      if (!error) {
        const resultMap: ITrayek = result
        const trayekhead = {
          trayekId: resultMap.Id,
          trayekName: resultMap.Name,
          trayekCode: resultMap.Code,
          trayekClass: resultMap.MsClass,
          trayekType: resultMap.TrayekType,
          isTol: resultMap.IsTol
        }
        const findTrayekByRegNumber = resultMap.TrayekDetails.find((x) => x.RegNumber === val.label)
        if (findTrayekByRegNumber) {
          const tempDate: any[] = []
          findTrayekByRegNumber.TrayekSubs.forEach((x) => {
            const ccc = {
              ...trayekhead,
              trayekSubId: x.Id,
              ppOrder: x.Order,
              label: `${x.DepartureTime} (PP${x.Order})`,
              value: x.DepartureTime
            }
            tempDate.push(ccc)
          })
          setFieldValue(`data[${index}].optionTime`, tempDate)
        }
      }
    }

    const changeVehicle = (val: any, index: number, setFieldValue: TFieldValue) => {
      setFieldValue(`data[${index}].vehicle`, val)
      if (val?.Trayek?.Id) {
        getVehicleTrayek(val, index, setFieldValue)
      }
    }

    const processSave = (payload: any) => {
      apiUseCase.postOrPut(endpointPlotting, route.params.id, payload)
        .then(({ error, message }) => {
          if (!error) {
            store.dispatch('setReloadDataList', true)
            store.dispatch('setShowModalFormDl', false)
            backToListPage()
            $toast.add({
              severity: 'success',
              detail: 'Plotting Kondektur berhasil disimpan',
              group: 'bc',
              closable: false,
              life: 3000
            })
          } else {
            store.dispatch('setShowModalFormDl', false)
            $toast.add({
              severity: 'error',
              detail: message,
              group: 'bc',
              closable: false,
              life: 3000
            })
          }
          store.dispatch('hideLoading')
        })
    }

    const onSubmit = (val: any) => {
      // eslint-disable-next-line no-unused-expressions
      refConfirmModal.value?.openModal({
        title: 'Konfirmasi Plotting Kondektur ?',
        subTitle: 'Pastikan data plotting kondektur sudah benar. Sistem tetap akan mengecek data',
        btnSubmitTitle: 'Simpan Plotting',
        imgContent: require('@/assets/img/modal-confirmation/plotting-save.svg')
      }).then((isConfirm) => {
        if (isConfirm) {
          const payload: any[] = []
          val.data.forEach((data: any) => {
            const dataFormTemp = {
              plottingDate: moment(data.date).format('YYYY-MM-DD'),
              condecturId: store.state.plotting.selectedConductor.Id,
              condecturUsername: store.state.plotting.selectedConductor.Username,
              condecturName: store.state.plotting.selectedConductor.Fullname,
              msVehicleId: data.vehicle.Id,
              regNumber: data.vehicle.Nopol,
              trayekId: data.time.trayekId,
              trayekName: data.time.trayekName,
              trayekCode: data.time.trayekCode,
              trayekSubId: data.time.trayekSubId,
              trayekClassName: data.time.trayekClass.Name,
              isTol: data.time.isTol,
              departureTime: data.time.value,
              ppOrder: data.time.ppOrder,
              driverId: data.driver?.Id,
              driverName: data.driver?.label,
              helperId: data.helper?.Id,
              helperName: data.helper?.label,
              trayekTypeId: data.time.trayekType.Id,
              trayekTypeCode: data.time.trayekType.Code,
              trayekTypeName: data.time.trayekType.Name,
            }

            payload.push(dataFormTemp)
          })
          processSave(payload)
        }
      })
    }

    const showFormDialog = computed({
      get: () => store.state.headerButton.isShowDialogForm,
      set: (val) => {
        store.dispatch('setHeaderButtonActionShowDialogForm', val)
      }
    })

    const openFormDialog = () => {
      showFormDialog.value = true
    }

    const onUpdateDriver = async (val: any, date: any) => {
      store.dispatch('showLoading')
      console.log(val, date)
      if (val && date) {
        console.log('params', [val, moment(date.toDateString()).format('YYYY-MM-DD')])
        const filterDate = moment(date.toDateString()).format('YYYY-MM-DD')
        currentSchedules.value = []
        try {
          const { result, error, message } = await apiUseCase.get(`${endpointPlotting}/Driver/${val.value}${returnUrlPrams({
            custom: [['startDate', '=', filterDate], ['endDate', '=', filterDate]]
          })}`)
          if (!error) {
            if (result.DriverPlottingCount > 0) {
              modalConfirmation.title = `Sopir ${result.DriverName} Sudah Mempunyai Jadwal`
              modalConfirmation.subTitle = 'Sopir sudah mempunyai jadwal, mohon cek jadwal sopir berikut ini : '
              modalConfirmation.show = true
              currentSchedules.value = result.DriverPlottings
              console.log('sch', currentSchedules.value)
            }
          } else {
            $toast.add({
              severity: 'error',
              detail: message,
              group: 'bc',
              closable: false,
              life: 3000
            })
          }
        } catch (error) {
          console.log('err', error)
        }
      }
      store.dispatch('hideLoading')
    }

    const hideConfirmDialog = () => {
      modalConfirmation.show = false
    }

    onMounted(() => {
      if (!store.state.plotting.selectedConductor) {
        backToListPage()
      } else {
        isLoading.value = false
      }
    })

    return {
      initialValue,
      defaultValue,
      icon,
      tabHeader,
      activeTab,
      tabsChange,
      changeVehicle,
      moment,
      schema,
      isLoading,
      onSubmit,
      showConfirmDialog,
      openFormDialog,
      store,
      refConfirmModal,
      onUpdateDriver,
      hideConfirmDialog,
      modalConfirmation,
      currentSchedules
    }
  }
})
