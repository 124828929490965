
import { defineComponent, getCurrentInstance, onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { Form, Field, FieldArray, ErrorMessage } from 'vee-validate'
import apiUseCase from '@/usecase/apiUseCase'
import moment from 'moment'

export default defineComponent({
  name: 'VerbalReport',
  components: {
  },
  setup() {
    const endpointKondekturLog = '/management/v1/ActivityLog/CondecturLogs'
    const logKondektur = ref() as any
    const store = useStore()

    const getAllData = () => {
      apiUseCase.get(`${endpointKondekturLog}?username=${store.state.plotting.selectedConductor.Username}`).then((response) => {
        console.log(response)
        logKondektur.value = response.result.slice(0, 5)
        console.log(logKondektur.value)
      })
    }

    onMounted(() => {
      getAllData()
    })
    return {
      logKondektur,
      moment
    }
  }
})
